body {
  text-align: center;
  background: rgb(246, 246, 248);
  font-family: 'Josefin Sans', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.contactH1 {
  margin: 30px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.linkedIn-btn,
.gitHub-btn,
.facebook-btn {
  position: relative;
  width: 40px;
  height: 40px;
  user-select: none;
  cursor: pointer;
  font-weight: bold;
  font-size: 38px;
  z-index: 2;
  margin: 5px;
  left: 45%;

}