.vendorsLogo img {
    width: 175px;
    padding: 10px;
    border-radius: 25px;
    margin: 10px;
    border: 1px solid black;
}

.fourLegLogo img {
    filter: invert(1);
    border: 1px solid white;
}

.vendorsImgExp img {
    width: 100%;
    border: 2px solid black;
    border-radius: 30px;
}

.vendorsH1 {
    margin: 50px auto;
    padding: 0 0 25px 0;
    width: 50%;
    font-size: 60px;
    font-weight: bold;
    border-bottom: 2px solid lightgray;
}

.vendorsDesc {
    width: 60%;
    margin: 35px auto;
    line-height: 2;
    padding: 15px;
    font-size: 18px;
}

.vendorCol {
    margin: 40px auto;
}

.vendorWrap {
    border: 2px solid black;
    padding: 10px;
    border-radius: 40px;
}

.vendorWrap h4 {
    margin: 15px;
    font-size: 30px;
}

.vendorRow h3 {
    font-size: 35px;
    font-weight: bold;
}

.vendorWrap button {
    width: 100%;
    background: rgb(124, 187, 124);
    color: white;
    border: 3px solid white;
    font-size: 20px;
    font-weight: bold;
    height: 60px;
    margin: 15px 0;
}

.vendorWrap button:hover {
    transition: all .5s ease-out;
    background: rgb(154, 214, 154);
}

.vendorSocials {
    padding: 10px;
}

.vendorSocials a svg {
    font-size: 30px;
}

@media (max-width: 992px) {

    .vendorsH1 {
        padding: 0 0 25px 0;
        width: 90%;
        font-size: 50px;
    }

    .vendorsDesc {
        width: 90%;
        padding: 10px;
        font-size: 15px;
    }
}