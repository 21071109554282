.subHeader {
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: beige;
    padding: 75px 200px;
}

.subHeader h2 {
    font-size: 70px;
    margin-bottom: 30px;
    font-weight: bold;
}

.subHeader p {
    font-size: 17px;
    margin-bottom: 30px;
}

.subHeader button {
    background: rgb(124, 187, 124);
    color: white;
    border: 3px solid white;
    font-size: 20px;
    font-weight: bold;
    height: 60px;
    width: 250px;
}

.main {
    width: 100%;
}

.main img {
    width: 100%;
}

#myVideo {
    z-index: -1;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.subHeader button:hover {
    transition: all .5s ease-out;
    background: rgb(154, 214, 154);
}

.boxes {
    margin-top: 50px;
}

.boxLink {
    text-decoration: none;
}

.image {
    width: 100%;
    height: 250px;
}

.textWrap {
    position: relative;
    bottom: 65px;
    background: rgba(154, 214, 154, .5);
    width: 100%;
    height: 65px;
    color: white;
    text-align: left;
    padding-left: 15px;
}

.textWrap:hover {
    transition: all .5s ease-out;
    background: rgb(123 171 123 / 50%);
}

.textWrap h3 {
    position: relative;
    top: 10px;
    font-weight: bold;
}

@media (max-width: 992px) {
    .subHeader {
        padding: 25px;
    }

    .subHeader h2 {
        font-size: 50px;
    }
}