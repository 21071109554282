.packagesH1 {
    margin: 50px auto;
    padding: 0 0 25px 0;
    width: 50%;
    font-size: 60px;
    font-weight: bold;
    border-bottom: 2px solid lightgray;
}

@media (max-width: 992px) {

    .packagesH1 {
        padding: 0 0 25px 0;
        width: 90%;
        font-size: 50px;
    }
}